import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import useBreederPostData from '../hooks/useBreederPostData';

const MAX_GRID_ITEM_COUNT = 6;
const VIDEO_ICON_SIZE = isLessThanBreakpoint('md') ? '24px' : '32px';

export default function BreederPostCardMediaGrid({
  className,
  gallery,
  breederPostId,
  breederProfileId
}) {
  const { openPostModal } = useBreederPostData();

  const visibleItems = gallery.slice(0, MAX_GRID_ITEM_COUNT);
  const totalItemCount = gallery.length;
  const visibleItemCount = visibleItems.length;

  const viewMoreButtonLabel =
    totalItemCount > visibleItemCount
      ? `+${totalItemCount - visibleItemCount}`
      : null;

  function openMediaCarousel(slideIndex) {
    openPostModal(breederPostId, breederProfileId, slideIndex);
  }

  function handleViewMoreClick(e) {
    e.stopPropagation();
    openMediaCarousel(visibleItemCount);
  }

  return (
    <div
      className={classnames(
        'BreederPostCardMediaGrid aspect-ratio',
        visibleItemCount === 5 ? 'aspect-ratio--5x6' : 'aspect-ratio--1x1',
        className
      )}
      data-test-id="breeder-post-card-media-grid"
    >
      <div className="aspect-ratio--object">
        <div
          className={classnames(
            'BreederPostCardMediaGrid__grid grid gap-4 h-100',
            `BreederPostCardMediaGrid__grid--${visibleItemCount}`
          )}
        >
          {visibleItems.map((item, index) => {
            const isVideo = item.type === 'Video';

            return (
              <ClickableDiv
                key={item.id}
                className="BreederPostCardMediaGrid__grid-item relative"
                onClick={e => {
                  e.stopPropagation();
                  openMediaCarousel(index);
                }}
              >
                <SmartImage
                  className="h-100 w-100 object-cover"
                  loadingClass="bg-light-gray"
                  crop
                  src={isVideo ? item.thumbnail_url : item.url}
                />
                {isVideo && (
                  <div className="bg-black-60 br-100 transform-center-all pv4 ph4 pv6-md ph6-md">
                    <Icon
                      width={VIDEO_ICON_SIZE}
                      height={VIDEO_ICON_SIZE}
                      name="fetch-play"
                      className="white"
                    />
                  </div>
                )}
              </ClickableDiv>
            );
          })}
          {viewMoreButtonLabel && (
            <button
              type="button"
              className="BreederPostCardMediaGrid__view-more br-pill font-12 md:font-14 fw-medium white absolute bottom-0 right-0 mv2 mh2 pv1 pv2-md ph2 ph4-md z-999"
              onClick={handleViewMoreClick}
            >
              {viewMoreButtonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

BreederPostCardMediaGrid.propTypes = {
  className: PropTypes.string,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['Photograph', 'Video']),
      id: PropTypes.number,
      caption: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  breederPostId: PropTypes.number.isRequired,
  breederProfileId: PropTypes.number.isRequired
};

BreederPostCardMediaGrid.defaultProps = {
  className: null
};
