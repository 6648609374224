import {
  getShortDateString,
  getTimeString,
  isToday
} from 'dpl/shared/utils/date';

export function getFormattedPostDate(dateStr) {
  const postDate = new Date(dateStr);
  const formattedDate = getShortDateString(postDate, true, true);

  if (isToday(postDate)) {
    return `${formattedDate}, ${getTimeString(postDate)}`;
  }

  return formattedDate;
}
